import React from 'react';
import { graphql } from 'gatsby';
import type { IGatsbyImageData } from 'gatsby-plugin-image';

import ProjectWriteupLayout from 'layouts/ProjectWriteup';

interface WriteupPageData {
	wpProjectWriteup: {
		title: string;
		projectWriteup: {
			galleryImages: string;
			gallerySubtitle: string | null;
			galleryTitle: string;
			heroImage: {
				localFile: {
					childImageSharp: {
						gatsbyImageData: IGatsbyImageData;
					};
				};
			};
			heroTextContent: string | null;
			pageContent: string;
			subtitle: string | null;
		};
		seo: {
			metaDesc: string;
			title: string;
		};
	};
}

export default ({ data }: { data: WriteupPageData }) => {
	const writeup = data?.wpProjectWriteup?.projectWriteup;

	return (
		<ProjectWriteupLayout
			title={data?.wpProjectWriteup?.seo?.title}
			metaTitle={data?.wpProjectWriteup?.seo?.title}
			metaDescription={data?.wpProjectWriteup?.seo?.metaDesc}
			heroImage={writeup?.heroImage?.localFile?.childImageSharp?.gatsbyImageData}
			heroTitle={data?.wpProjectWriteup?.title}
			heroSubtitle={writeup?.subtitle}
			heroContent={writeup?.heroTextContent}
			writeupContent={writeup?.pageContent}
			galleryImages={writeup?.galleryImages}
			galleryTitle={writeup?.galleryTitle}
			gallerySubtitle={writeup?.gallerySubtitle}
		/>
	);
};

export const data = graphql`
	query WriteupTestPage {
		wpProjectWriteup(id: { eq: "cG9zdDoyNzk=" }) {
			title
			projectWriteup {
				galleryImages
				gallerySubtitle
				galleryTitle
				heroImage {
					localFile {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
				heroTextContent
				pageContent
				subtitle
			}
			seo {
				metaDesc
				title
			}
		}
	}
`;

// test cG9zdDoyNzU=
// test2 cG9zdDoyNzk=
// og cG9zdDoyMDI=
// test 3 cG9zdDoyODE=
